<template>
  <textarea v-if="type === 'textarea'" class="base-input base-input--textarea" :class="modStyle" v-bind="$attrs"
            :value="value" :style="textareaStyles" ref="textarea"
            @input="onTextareaInput" @focus="$emit('focus')" @blur="$emit('blur')" @keypress="onKeypress"
            @keydown="onKeydown"/>
    <cleave v-else-if="options" class="base-input" :class="modStyle" v-bind="$attrs" :value="value" ref="input" :type="type"
            :options="options"
            @input="onInput" @focus.native="onFocus" @blur.native="onBlur" @keypress.native="onKeypress"
            @keydown.native="onKeydown"/>
    <input v-else class="base-input" :class="modStyle" v-bind="$attrs" :value="value" ref="input" :type="type"
           @input="onInput($event.target.value)" @focus="onFocus" @blur="onBlur" @keypress="onKeypress"
           @keydown="onKeydown">
</template>
<script>
  import Cleave from 'vue-cleave-component';

  export default {
    name: 'BaseInput',
    components: {
      Cleave
    },
    props: {
      modStyle: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: 'text'
      },
      value: {
        type: [String, Number],
        default: ''
      },
      minHeight: {
        type: [String, Number],
      },
      options: {
        type: Object,
      }
    },
    data() {
      return {
        height: null,
        cleave: null
      }
    },
    computed: {
      textareaStyles() {
        const styles = {
          height: this.height + 'px'
        };
        if(this.minHeight) {
          styles.minHeight = this.minHeight - 24 + 'px'
        }
        return styles
      }
    },
    methods: {
      async onTextareaInput(e) {
        this.$emit('input', e.target.value);
        this.height = 0;
        await this.$nextTick();
        this.height = this.$refs.textarea.scrollHeight;
      },
      onInput(e) {
        this.$emit('input', e)
      },
      onFocus(e) {
        this.$emit('focus', e);
      },
      onBlur(e) {
        this.$emit('blur', e);
      },
      onKeydown(e) {
        this.$emit('keydown', e)
      },
      onKeypress(e) {
        this.$emit('keypress', e);
      }
    },
  }
</script>
<style lang="scss">
    .base-input {
        background-color: transparent;
        padding: 0;
        border: 0;
        resize: none;
        overflow: hidden;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        font-weight: inherit;
    }
</style>
