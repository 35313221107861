<template>
	<label class="app-input" :class="className" :style="style" @click="$emit('click')" @keydown.enter.prevent="entered">
		<div class="app-input__prepend" v-if="$slots.prepend"><slot name="prepend" /></div>
		<div v-if="disabled" class="app-input__field">
			<slot>{{ value }}</slot>
		</div>
		<base-input
			v-else
			class="app-input__field"
			:value="value"
			ref="input"
			:type="type"
			@input="$emit('input', $event)"
			:autofocus="autofocus"
			v-bind="$attrs"
			@keypress="onKeypress"
			@focus="onFocus"
			@blur="onBlur"
			@change="$emit('change', $event)"
		/>
		<div class="app-input__append" v-if="$slots.append"><slot name="append" /></div>
	</label>
</template>
<script>
import BaseInput from "./BaseInput";
import "../../assets/styles/components/app-input-a.scss";
export default {
	name: "AppInput",
	components: { BaseInput },
	props: {
		value: {
			type: [String, Number],
			default: "",
		},
		theme: String,
		radius: {
			type: [String, Number],
			default: 8,
		},
		height: {
			type: [String, Number],
			default: 40,
		},
		paddingX: {
			type: [String, Number],
			default: 15,
		},
		fontSize: {
			type: [String, Number],
			default: 14,
		},
		fontWeight: {
			type: [String, Number],
			default: 400,
		},
		lineHeight: [String, Number],
		autofocus: Boolean,
		type: {
			type: String,
			default: "text",
		},
		disabled: Boolean,
		isInvalid: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			focused: false,
			excludedClasses: "",
		};
	},
	computed: {
		style() {
			const style = {
				"--padding-x": this.paddingX + "px",
				"--radius": this.radius + "px",
				"--height": this.height + "px",
				"--font-size": this.fontSize + "px",
				"--line-height": (this.lineHeight || this.fontSize) + "px",
				"--font-weight": this.fontWeight + "px",
			};
			if (this.width) {
				style["--width"] = this.width + "px";
			}
			return style;
		},
		className() {
			const className = [
				{
					"app-input--focused": this.focused,
					"app-input--disabled": this.disabled,
					"app-input--textarea": this.type === "textarea",
					["app-input--theme-" + this.theme]: this.theme,
					"app-input--width": this.width,
					"is-invalid": this.isInvalid,
				},
			];
			return className;
		},
	},
	methods: {
		entered() {
			this.$emit("inputEnter");
		},
		onBlur(e) {
			this.focused = false;
			this.$emit("blur", e);
		},
		onFocus(e) {
			this.focused = true;
			this.$emit("focus", e);
		},
		onKeypress(e) {
			this.$emit("keypress", e);
		},
		focus() {
			this.$refs.input.$el.focus();
		},
	},
	mounted() {
		if (this.autofocus) {
			this.focused = true;
			this.focus();
		}
	},
};
</script>
<style lang="scss"></style>

